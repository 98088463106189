<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Tipos de Usuario</h1>
        </div>
        <br />
        <div class="row">
          <div class="col-10">
            <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                <div class="input-group">
                  <input
                    type="text"
                    v-model="keywords"
                    class="form-control"
                    placeholder
                    aria-label="Buscar por palabras claves"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-primary">Buscar</button>
                    <!--
                    <button
                      type="button"
                      v-on:click="newSearch()"
                      class="btn btn-primary"
                      data-toggle="modal"
                      data-target="#searchModal"
                    >Búsqueda avanzada</button>
                    <searchtpousu v-on:search="searchItems" v-bind:item="search"></searchtpousu>
                    -->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class>
              <button
                v-on:click="$router.push({ name: 'CreateTpousu', params: { add: true, search: search } });"
                class="btn btn-primary"
              >
                <i data-feather="plus-circle"></i>Crear Tipo de Usuario
              </button>
            </div>
            <br />
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="list-doc"
                role="tabpanel"
                aria-labelledby="list-doc-list"
              >
                <div class="table-responsive">
                  <table class="table table-hover table-bordered">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Código</td>
                        <td>Nombre</td>
                        <td>Sólo aplicación</td>
                        <td class="text-center">
                          <input type="checkbox" v-model="allChecked" v-on:click="checkAllItems" />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in items">
                        <td class="text-center">
                          <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>
                        </td>
                        <td>{{ item.tus_cdgo }}</td>
                        <td>{{ item.tus_nmbre }}</td>
                        <td>{{ item.tus_app | formatBoolean }}</td>
                        <td class="text-center">
                          <input type="checkbox" v-bind:value="item._id" v-model="delItems" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="list-doc-archivo"
                role="tabpanel"
                aria-labelledby="list-archivo-list"
              >...</div>
            </div>
            <br />
            <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
              <i data-feather="delete"></i>Eliminar
            </button>
            <!--{{delItems}}-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VIcon } from 'vuetify/lib';
import SearchTpousu from "./SearchTpousu.vue";
import { formatBoolean } from './../../plugins/filters';

export default {
  components: {
    VIcon,
    searchtpousu: SearchTpousu
  },
  data() {
    return {
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords: "",
      search: {},
      filter: false // Items are filtered
    };
  },
  filters: {
    formatBoolean
  },
  computed: {
    checkedItems() {
      if (this.delItems.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function() {
    feather.replace();
  },
  created: function() {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.fetchItems();
    }
  },
  methods: {
    fetchItems() {
      let uri = "/tpousus";
      this.axios.get(uri).then(response => {
        this.items = response.data;
        this.delItems = [];
      });
    },
    deleteItem(id, index) {
      const response = confirm("Está seguro que desea eliminar?");
      if (response) {
        let uri = "/tpousus/delete/" + id;
        this.axios
          .get(uri)
          .then(res => {
            this.items.splice(index, 1);
          })
          .catch(err => console.log(err));
      }
      return;
    },
    deleteItems() {
      if (this.delItems.length > 0) {
        const response = confirm(
          "Está seguro que desea eliminar los registros seleccionados?"
        );
        if (response) {
          for (var i = 0; i < this.delItems.length; i++) {
            //alert('Item(' + i + '): ' + this.delItems[i]);
            let uri = "/tpousus/delete/" + this.delItems[i];
            this.axios
              .get(uri)
              .then(res => {
                //this.items.splice(index, 1);
                if (this.search) {
                  this.searchItems(this.search);
                }
              })
              .catch(err => {
                console.log(err);
                for (var m = 0; m < this.items.length; m++) {
                  if (this.delItems[i] == this.items[m]._id) {
                    alert("No se ha podido eliminar el item " + this.items[m]);
                    m = this.items.length;
                  }
                }
              });
          }
          if (this.filter) {
            this.searchItems(this.search);
          } else {
            this.fetchItems();
          }
        }
      }
      return;
    },
    checkAllItems() {
      this.delItems = [];
      if (!this.allChecked) {
        for (var i = 0; i < this.items.length; i++) {
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    searchItems(p) {
      //alert(key);
      if (p != null) {
        if (p.key) {
          this.keywords = p.key;
        } else {
          this.keywords = "";
        }
        this.message = "Buscando Tipos de Usuario";
        let uri = "/tpousus/search";
        this.axios
          .post(uri, p)
          .then(response => {
            console.log(response);
            this.items = response.data;
            this.search = p;
            this.delItems = [];
            this.filter = true;
          })
          .catch(err => {
            console.log(err);
            this.message = "¡Error al buscar los Tipos de Usuario" + err;
          });
      }
    },
    closeSearch() {
      this.filter = false;
      this.search = {};
      this.keywords = "";
      this.items = [];
      this.fetchItems();
    },
    newSearch() {
      this.search = {};
    },
    createItem() {
      this.$router.push({
        name: "CreateTpousu",
        params: { add: true, search: this.search }
      });
    },
    editItem(id) {
      this.$router.push({
        name: "CreateTpousu",
        params: { id: id, add: false, search: this.search }
      });
    }
  }
};
</script>